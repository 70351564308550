import { template as template_ef45c88f247e4dbb87ebb3943ca9f088 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ef45c88f247e4dbb87ebb3943ca9f088(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
