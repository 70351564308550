import { template as template_f8bbaf17199a491ba2e3af3ca8fbb003 } from "@ember/template-compiler";
const WelcomeHeader = template_f8bbaf17199a491ba2e3af3ca8fbb003(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
