import { template as template_4c827692495e4d02bdc769fb15fef818 } from "@ember/template-compiler";
const FKText = template_4c827692495e4d02bdc769fb15fef818(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
